import { defineStore, useMinisearchInstruments } from '#imports'

export const useSearchCatalog = defineStore('searchCatalog', () => {
  const minisearchInstruments = useMinisearchInstruments({
    relatedVideos: true,
    useSearchQueryUrl: true,
  })

  return {
    DRUMKIT_TAG_ID: minisearchInstruments.DRUMKIT_TAG_ID,
    mode: minisearchInstruments.mode,
    changeMode: minisearchInstruments.changeMode,

    // store objects
    tags: minisearchInstruments.tags,
    tagTypes: minisearchInstruments.tagTypes,
    packs: minisearchInstruments.packs,
    terms: minisearchInstruments.terms,
    suggestions: minisearchInstruments.suggestions,
    refiners: minisearchInstruments.refiners,
    refinerList: minisearchInstruments.refinerList,
    priceRange: minisearchInstruments.priceRange,
    priceRangeValue: minisearchInstruments.priceRangeValue,
    priceRangeList: minisearchInstruments.priceRangeList,
    searchInstrumentsResults: minisearchInstruments.searchInstrumentsResults,
    currentPage: minisearchInstruments.currentPage,
    totalPages: minisearchInstruments.totalPages,

    // store functions
    isSearchLoaded: minisearchInstruments.isSearchLoaded,
    refreshStore: minisearchInstruments.refreshStore,
    isRefinersSelectionEmpty: minisearchInstruments.isRefinersSelectionEmpty,
    cleanupRefiners: minisearchInstruments.cleanupRefiners,
    selectedRefinersItems: minisearchInstruments.selectedRefinersItems,
    unselectRefinersItem: minisearchInstruments.unselectRefinersItem,
    getSuggestionsFromTerms: minisearchInstruments.getSuggestionsFromTerms,
    highlightTerms: minisearchInstruments.highlightTerms,
    updateSearch: minisearchInstruments.updateSearch,
    getUrlSearchParams: minisearchInstruments.getUrlSearchParams,
    setUrlSearchParams: minisearchInstruments.setUrlSearchParams,
    getTagsByType: minisearchInstruments.getTagsByType,
    getTagParentsByType: minisearchInstruments.getTagParentsByType,
    getTagChildrenByParentId: minisearchInstruments.getTagChildrenByParentId,
    getTagById: minisearchInstruments.getTagById,
    getPackById: minisearchInstruments.getPackById,
  }
})
